.today--section {
    margin-bottom: 40px;
    padding: 0 50px;
}
.today--section h2 {
    font-size: 20px;
}
.today--section__box {
    background-color: white;
    padding: 20px;
    margin: 20px 0;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.btc--section {
    text-align: center;
    border-right: 1px solid #DAE1E9;
}
.btc--section h5 {
    font-size: 30px;
}
.eth--section {
    text-align: center;
    border-right: 1px solid #DAE1E9;
}
.eth--section h5 {
    font-size: 30px;
}
.ltc--section {
    text-align: center;
}
.ltc--section h5 {
    font-size: 30px;
}
@media (max-width: 480px) {
    .eth--section {
        border-right: none;
    }
    .btc--section {
        border-right: none;
    }
    .today--section {
        margin-top:  50px;
    }
}


.settingscard {
background-color: #ffffff;
padding: 10px;
margin-top: 66px;
}

.settingsforminput {
    width: 100%;
    margin-top: 5px !important;
    margin-bottom: 10px !important;
}

.settingsforminput2 {
    width: 100%;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
}

.settingstitle {
    font-size: 12px !important;
}

.settingstitle2 {
    font-size: 12px !important;
    padding-bottom: 20px;
}




.MuiCardContent-root {
    background-Color: '#cceded';
    width: '100%';
    padding: 0 !important;
    padding-bottom: 6px !important;
  }
  .MuiCardActions-root {
      padding: 0 !important;
      margin-top: 10px;
  }

  .appBar {
    z-Index: 5000;
    background-Color: '#00a6a6';
  }
  .heading {
    font-Size: 20px;
    flex-Basis: '33.33%';
    flex-Shrink: 0;
  }
  .content {
    flex-Grow: 1;
  }

  /*body .alertmsg2 .MuiAlert-standardError {
    visibility: visible !important;
    opacity: 1 !important;
    display: flex !important;
  }
  */


  body.submitalert .alertmsg-submit1 {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    height: auto !important;

  }
  body .alertmsg-submit1{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    height: 0;

  }