.today--section {
    margin-bottom: 40px;
    padding: 0 50px;
}
.today--section h2 {
    font-size: 20px;
}
.today--section__box {
    background-color: white;
    padding: 20px;
    margin: 20px 0;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.btc--section {
    text-align: center;
    border-right: 1px solid #DAE1E9;
}
.btc--section h5 {
    font-size: 30px;
}
.eth--section {
    text-align: center;
    border-right: 1px solid #DAE1E9;
}
.eth--section h5 {
    font-size: 30px;
}
.ltc--section {
    text-align: center;
}
.ltc--section h5 {
    font-size: 30px;
}
@media (max-width: 480px) {
    .eth--section {
        border-right: none;
    }
    .btc--section {
        border-right: none;
    }
    .today--section {
        margin-top:  50px;
    }
}

iframe {
    height: 100%;
    padding-top: 54px;
}
.homescreencontent, .root {
    display: flex;
    background-Color: #cceded;
    width: 100%;
    height: 100vh;
  }
.MuiAppBar-colorPrimary {

    background-color: #00a6a6 !important;
}
.content {
    flex-grow: 1;
    padding-Top: 0px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
.alertmsg {
    width: 100%;
    position: absolute;
    z-Index: 50003;
    padding-Top: 25px !important;
    bottom: 55px;
    left: 0;
}

.MuiAppBar-positionStatic {
    background-color: #00a6a6 !important;
    z-index: 5001;
}
.twousermenu {
    position: fixed;
    width: 100%;
    z-index: 5001 !important;
    height: calc(100% - 150px);
    top: 52px;
}
.MuiPaper-elevation4 {
    box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.25) !important;

}
.MuiTab-textColorPrimary {
    color: #cceded !important;
}

.MuiTab-textColorPrimary.Mui-selected {
color: #ffffff !important;

}
.MuiTabs-indicator {
    background-color: #ffffff !important;
}

.MuiAppBar-positionFixed.MuiAppBar-colorPrimary {
    z-index: 5000;
}
.twousermenu .MuiTypography-root  {
    height: 100%;
}
.react-swipeable-view-container {
    padding-top: 2px !important;
}
.twousermenu .react-swipeable-view-container .MuiBox-root {
    padding: 0 !important;
    height: 100%;
}
.twousermenu .react-swipeable-view-container {
    padding: 0 !important;
    height: 100%;
}

.twousermenu .react-swipeable-view-container .MuiBox-root iframe {
    padding-top: 0 !important;
    height: 100% !important;
    margin-top: 48px;
}

.twousermenu div:nth-child(2) {
    height: 100%;
}
.twousermenu div:nth-child(2) .react-swipeable-view-container. .MuiTypography-root.MuiTypography-body1 {
    padding-top: 48px;
}


@media all and (orientation:landscape) {
    .homescreencontent .content {
        height: 100%;
        margin-bottom: 0 !important;
    }

 }
 
 @media all and (orientation:portrait) {

 }

 .homescreencontent .settingscard {
     padding-left: 24px;
     padding-right: 24px;
     padding-top: 24px;
     margin-left: 12px;
     margin-right: 12px;
 }
 .logintitle {
     font-size: 0.9375rem !important;
     color: rgba(0, 0, 0, 0.87) !important;
 }

 .addtohomescreen {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 80001;
}

.addtohomescreen button {
    float: right;
    margin-right: 20px;
    margin-bottom: 20px;
}
.addtohomescreen .MuiListItemAvatar-root {
    margin-right: 15px;
}

.syncbutton {
    position: absolute;
    right: 0;
    margin-right: 12px;
}