html {
  background-color: #cceded;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #00a6a6 !important;
}
body.offline .alertmsg, body.copyalert .alertmsg-copy {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  display: flex !important;
  width: 100%;
}
body .alertmsg, body .alertmsg-copy {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  display: none;
}

.alertmsg-copy .MuiAlert-standardSuccess {
  width: 100%;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiBottomNavigation-root {
  position: fixed;
  bottom: 0px;
  z-index: 60000;
  width: 100%;
}

.MuiListItem-button.Mui-selected, .MuiListItem-button.Mui-selected .MuiListItemIcon-root {
  color: #00a6a6 !important;
}
.MuiListItem-root.Mui-selected {
  background-color: #ffffff !important;
}

.MuiBottomNavigationAction-root.Mui-selected {
  color: rgba(0, 0, 0, 0.54) !important;
}

@media all and (orientation:portrait), (max-width: 520px) {
  .MuiDrawer-root {
    display: none;
  }
  .navbottom {
    position: fixed;
    transform: translate3d(0,0,0);
    bottom: 0px;
    width: 100%;
    height: 15px;
    background-color: green;
  }
  .hidebottomnav .MuiBottomNavigation-root {
    /*display: none !important;*/
  }

  }
  
  @media all and (orientation:landscape) and (min-width: 520px) {
    .MuiBottomNavigation-root {
      display: none !important;
    }
    #iframebox {
      padding-bottom: 0px !important;
    }
  }

  @media (min-width:961px)  {
    .MuiDrawer-root {
      display: block !important;
    }
    .MuiBottomNavigation-root {
      display: none !important;
    }
    #iframebox {
      padding-bottom: 0px !important;
    }
   }

   @media (max-width:300px)  {
    #iframebox {
      padding-bottom: 0px !important;
    }
    main {
      padding-bottom: 0px !important;
      
    }
   }

   @media (max-width: 991px)  {
    a {
      -webkit-tap-highlight-color: transparent;
    }
    body, div {
      -webkit-touch-callout: none;
      -webkit-overflow-scrolling: auto;
    }
    *::-webkit-scrollbar {
      width: 0px;
     }
   }



   .mysurvey .MuiBottomNavigationAction-label {
     width: 120% !important;
     text-align: center;
   }

   .MuiBottomNavigationAction-label {
    font-size: 0.75rem !important;
   }

   .MuiTypography-h6.textheading {
    font-size: 0.9375rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400 !important;
    line-height: 1.8 !important;
    letter-spacing: 0.0195em !important;
    margin-bottom: 10px;
  }

.MuiListItemText-primary {
  text-transform: none !important;
}
.MuiListItemText-secondary {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-transform: none !important;
  font-size: 0.75rem !important;
}


.MuiListItemAvatar-root {
  min-width: 45px !important;
}


.MuiAppBar-positionFixed {
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
  transform: translate3d(0,0,0);
}

.hideinstaller .addtohomescreen {
  display: none;
}

.nav-settings .nav-settings, .nav-home .nav-home, .nav-about .nav-about {
  color: #00a6a6!important;
}

.nav-settings .nav-settings .MuiSvgIcon-root, .nav-home .nav-home .MuiSvgIcon-root, .nav-about .nav-about .MuiSvgIcon-root {
  color: #00a6a6!important;
}

